import React from 'react';
import { Link } from 'gatsby';

import imgLB from '../../assets/image/apyke-black-800.png';
import imgLW from '../../assets/image/apyke-white-800.png';
import imgFS from '../../assets/image/inner-page/png/yellow-footer-shape.png';

const Footer = ({ className, ...rest }: any) => {
  return (
    <>
      <div className={`bg-dark-cloud pt-13 pt-lg-5 pb-7 dark-mode-texts position-relative ${className}`} {...rest}>
        <div className="container">
          <div className="row pb-lg-5">
            <div className="col-lg-4 col-md-5 col-md-3 col-xs-8">
              <div className="pr-xl-20 mb-11 mb-lg-0 ">
                <div className="brand-logo mb-0">
                  <Link to="/#">
                    <img className="mx-auto mx-0 light-version-logo default-logo" style={{ maxHeight: '40px' }} src={imgLB} alt="" />
                    <img src={imgLW} alt="" style={{ maxHeight: '40px' }} className="dark-version-logo mx-auto mx-0" />
                  </Link>
                </div>
                <p className="font-size-5 mb-0 text-bali-gray pr-sm-10 pr-md-0">Stay in sync with pull requests using your go-to PR notification app.</p>
                <div className="mt-8">
                  <Link to="https://apyke.freshdesk.com/support/tickets/new" className="btn btn-blue-3 btn-sm rounded-5 text-lily-white font-size-3">
                    Ask Question
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 offset-xl-1">
              <div className="row">
                {/* Single Widgets */}
                <div className="col-md-6 col-xs-12">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-6 font-weight-normal mb-2 text-bali-gray">Quick Links</h4>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <Link to="/faq" className="font-size-5 text-lily-white gr-hover-text-blue-3">
                          Frequently Asked Question
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/terms" className="font-size-5 text-lily-white gr-hover-text-blue-3">
                          Terms & Conditions
                        </Link>
                      </li>
                      <li className="mb-2">
                        <Link to="/privacy-policy" className="font-size-5 text-lily-white gr-hover-text-blue-3">
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}

                {/* Single Widgets */}
                <div className="col-lg-6 col-md-5 col-xs-8">
                  <div className="mb-10 mb-lg-0 mr-xl-12">
                    <h4 className="font-size-6 font-weight-normal mb-2 text-bali-gray">Contacts</h4>
                    <p className="font-size-5 mb-0 text-lily-white">Feel free to get in touch with us via contact form.</p>
                  </div>
                </div>
                {/* End Single Widgets */}
              </div>
            </div>
          </div>
          <div className="row align-items-center pt-10 border-top">
            <div className="col-lg-12 text-center text-lg-left">
              <div className="copyright">
                <p className="mb-0 font-size-3 text-bali-gray">© DKC Software 2024, All Rights Reserved</p>
              </div>
            </div>
            {/* <div className="col-lg-6 text-center text-lg-right">
              <div className="footer-right mt-5 mt-lg-0">
                <ul className="list-unstyled d-flex align-items-center justify-content-center justify-content-lg-end mb-0">
                  <li>
                    <Link to="/#" className="text-white gr-hover-text-blue-3 ml-7">
                      <i className="fab fa-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className="text-white gr-hover-text-blue-3 ml-7">
                      <i className="fab fa-facebook-f" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className="text-white gr-hover-text-blue-3 ml-7">
                      <i className="fab fa-google" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
        <div className="footer-yellow-shape-img">
          <img src={imgFS} alt="" />
        </div>
      </div>
    </>
  );
};

export default Footer;
