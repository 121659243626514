import React from 'react';
import { Link } from 'gatsby';
import imgLB from '../../assets/image/apyke-black-800.png';
import imgLW from '../../assets/image/apyke-white-800.png';

const Footer = ({ className, ...rest }: any) => {
  return (
    <>
      <div className={`bg-default-1 pb-13 pb-lg-10 ${className}`} {...rest}>
        <div className="container">
          <hr className="border-top border-default-color-2 m-0 p-0" />
          <div className="row pt-12 pt-lg-25 justify-content-center">
            <div className="col-lg-4 col-md-5 col-md-3">
              <div className="pr-xl-20 mb-11 mb-lg-0">
                <div className="brand-logo mb-7 mb-lg-8">
                  <Link to="/#">
                    <img className="mx-auto mx-0 light-version-logo default-logo" src={imgLB} alt="" />
                    <img className="dark-version-logo mx-auto mx-0" src={imgLW} alt="" />
                  </Link>
                </div>
                <p className="font-size-5 line-height-28 mb-0">Apyke has a collection of latest 2,500 jobs to join next companies.</p>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 offset-lg-1">
              <div className="row">
                {/* Single Widgets */}
                <div className="col-md-4 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-6 font-weight-medium mb-10">Community</h4>
                    <ul className="list-unstyled">
                      <li className="mb-7">
                        <Link to="/#" className="font-size-5 text-stone gr-hover-text-primary">
                          For Talents
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link to="/#" className="font-size-5 text-stone gr-hover-text-primary">
                          For Companies
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link to="/#" className="font-size-5 text-stone gr-hover-text-primary">
                          Facebook Group
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link to="/#" className="font-size-5 text-stone gr-hover-text-primary">
                          FAQ
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-md-4 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-6 font-weight-medium mb-10">About us</h4>
                    <ul className="list-unstyled">
                      <li className="mb-7">
                        <Link to="/#" className="font-size-5 text-stone gr-hover-text-primary">
                          Meet the Team
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link to="/#" className="font-size-5 text-stone gr-hover-text-primary">
                          Our Story
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link to="/#" className="font-size-5 text-stone gr-hover-text-primary">
                          Career
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-md-4 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-6 font-weight-medium mb-10">Useful</h4>
                    <ul className="list-unstyled">
                      <li className="mb-7">
                        <Link to="/#" className="font-size-5 text-stone gr-hover-text-primary">
                          For Talents
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link to="/#" className="font-size-5 text-stone gr-hover-text-primary">
                          For Companies
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link to="/#" className="font-size-5 text-stone gr-hover-text-primary">
                          Facebook Group
                        </Link>
                      </li>
                      <li className="mb-7">
                        <Link to="/#" className="font-size-5 text-stone gr-hover-text-primary">
                          FAQ
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
              </div>
            </div>
          </div>
          <div className="row align-items-center pt-3 pt-lg-22">
            <div className="col-md-6 text-center text-md-left">
              <div className="copyright mb-5">
                <p className="mb-0 font-size-3 text-stone">© Finity 2019, All Rights Reserved</p>
              </div>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <div className="footer-right mb-5">
                <p className="mb-0 font-size-3 text-stone">
                  {' '}
                  Built with love by <Link to="/#">Grayic</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
