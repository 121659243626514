import React, { useState } from 'react';
import { Link } from 'gatsby';
import { ContextInterface, Theme } from './types';

export const themeConfigDefault: Theme = {
  bodyDark: false,
  headerDark: false,
  footerDark: false,
  headerStyle: 'style1', //style2, style3
  headerFluid: true,
  headerButton: (
    <>
      <Link to="https://apyke.freshdesk.com/support/tickets/new" target="_blank" className="btn heading-default-color gr-hover-text-primary">
        Support
      </Link>
    </>
  ),
  headerClassName: 'site-header--menu-logo-middle',
  headerSocial: false,
  headerLogoClassName: '',
  footerStyle: 'style1',
  footerClassName: '',
};

const initContext: ContextInterface = {
  theme: themeConfigDefault,
  changeTheme: () => {},
  videoModalVisible: false,
  toggleVideoModal: () => {},
  visibleOffCanvas: false,
  toggleOffCanvas: () => {},
  closeOffCanvas: () => {},
};
const GlobalContext = React.createContext(initContext);

const GlobalProvider = ({ children }: any) => {
  const [theme, setTheme] = useState<Theme>(themeConfigDefault);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);

  const changeTheme = (themeConfig: Theme = themeConfigDefault) => {
    setTheme({
      ...theme,
      ...themeConfig,
    });
  };

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible);
  };

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas);
  };

  const closeOffCanvas = () => {
    setVisibleOffCanvas(false);
  };

  return (
    <GlobalContext.Provider
      value={{
        theme: theme,
        changeTheme,
        videoModalVisible,
        toggleVideoModal,
        visibleOffCanvas,
        toggleOffCanvas,
        closeOffCanvas,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
