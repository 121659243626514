import React, { useEffect, useState, createContext, useContext, ReactNode, useCallback } from 'react';

export interface DownloadContextValue {
  downloadAvailable: boolean;
  downloadableFile: string | null;
  availableFiles: string[];
}

const initContextValues = { downloadAvailable: false, downloadableFile: null, availableFiles: [] };
const DownloadContext = createContext<DownloadContextValue>(initContextValues);

export const useDownload = (): DownloadContextValue => {
  const context = useContext(DownloadContext);
  if (!context) {
    throw new Error('useGithub must be used within a GithubContext');
  }

  return context;
};

export const DownloadProvider = ({ children }: { children: ReactNode }) => {
  const [downloadAvailable, setDownloadAvailable] = useState<boolean>(false);
  const [availableFiles, setAvailableFiles] = useState<string[]>([]);
  const [downloadableFile, setDownloadableFile] = useState<string | null>(null);

  const fetchDownloadFiles = async () => {
    const url = 'https://download.apyke.com/list.json';

    try {
      const response = await fetch(url);

      if (!response.ok) {
        return setDownloadAvailable(false);
      }

      const data = await response.json();
      setDownloadAvailable(data);
      setDownloadAvailable(true);

      if (!data.length) {
        return;
      }

      const latestFile = data[0];

      setDownloadableFile(latestFile);
    } catch (error) {
      setAvailableFiles([]);
      setDownloadAvailable(false);
    }
  };

  useEffect(() => {
    fetchDownloadFiles();
  }, []);

  const value = {
    downloadAvailable,
    downloadableFile,
    availableFiles,
  };

  return <DownloadContext.Provider value={value}>{children}</DownloadContext.Provider>;
};
